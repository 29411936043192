import React from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { addvideo } from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import * as yup from "yup";
import { Form, Formik } from "formik";
import Div from "@jumbo/shared/Div/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  url: yup.string().required("Url is required"),
  category: yup.string().required("category is required"),
});

const Addvideo = () => {
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const style = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  };

  const handleSubmit = (data, setSubmitting, resetForm) => {
    addvideo(
      data,
      (response) => {
        console.log(response);
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setSubmitting(false);
          resetForm();
        } else {
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
          setSubmitting(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };
  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={6} xs={12}>
        <JumboCardQuick title={"Add Video"} noWrapper>
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}

          <List disablePadding sx={{ mb: 2 }}>
            <Formik
              validateOnChange={true}
              initialValues={{
                name: "",
                url: "",
                category: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(data, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                handleSubmit(data, setSubmitting, resetForm);
              }}
            >
              {({ isSubmitting, values, setFieldValue, errors }) => (
                <Form
                  style={{ textAlign: "left" }}
                  noValidate
                  autoComplete="off"
                >
                  <Div sx={{ mt: 1, mb: 2, pl: 2, pr: 2 }}>
                    <FormControl fullWidth id="customizer-select-locale">
                      <InputLabel id="demo-simple-select-standard-label">
                        Page Category
                      </InputLabel>
                      <Select
                        size={"small"}
                        labelId="demo-simple-select-standard-label"
                        value={values.category}
                        label="Page Category"
                        onChange={(e) =>
                          setFieldValue("category", e.target.value)
                        }
                        error={errors.category}
                      >
                        <MenuItem value="Education">Education</MenuItem>
                        <MenuItem value="Website Navigation">
                          Website Navigation
                        </MenuItem>
                      </Select>
                      <FormHelperText>{errors.category}</FormHelperText>
                    </FormControl>
                  </Div>

                  <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                    <JumboTextField
                      fullWidth
                      name="name"
                      label="Title"
                      type="text"
                    />
                  </Div>

                  <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                    <JumboTextField
                      fullWidth
                      name="url"
                      label="URL (e.g: https://www.youtube.com/embed/5oakY5vt7MA)"
                      type="text"
                      multiline
                      rows={4}
                    />
                  </Div>
                  <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                    <LoadingButton
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ mb: 3 }}
                      loading={isSubmitting}
                    >
                      Submit
                    </LoadingButton>
                  </Div>
                </Form>
              )}
            </Formik>
          </List>
        </JumboCardQuick>
      </Grid>
    </Grid>
  );
};

export default Addvideo;
